import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import DownloadContent from '../../components/downloads/DownloadContent';

declare global {
  interface Navigator {
    userAgentData?: {
      readonly platform: string;
    };
  }
}

export default () => {
  useEffect(() => {
    let userPlatform = 'unknown';
    const { navigator } = window;
    userPlatform = (
      navigator?.userAgentData?.platform ||
      navigator?.platform ||
      'unknown'
    ).toLowerCase();

    let platform = 'mac';

    if (userPlatform.includes('win')) {
      platform = 'windows';
    }

    navigate(`/downloads/${platform}`, { replace: true });
  });

  return <DownloadContent />;
};
